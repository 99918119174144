import(/* webpackMode: "eager", webpackExports: ["AuthKitProvider"] */ "/vercel/path0/node_modules/.pnpm/@workos-inc+authkit-nextjs@0.17.2_next@14.2.8_react-dom@18.3.1_react@18.3.1__react@18.3.1__re_zssm56lpwdwh2igou357bisdny/node_modules/@workos-inc/authkit-nextjs/dist/esm/authkit-provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["MinMaxButton"] */ "/vercel/path0/node_modules/.pnpm/@workos-inc+authkit-nextjs@0.17.2_next@14.2.8_react-dom@18.3.1_react@18.3.1__react@18.3.1__re_zssm56lpwdwh2igou357bisdny/node_modules/@workos-inc/authkit-nextjs/dist/esm/min-max-button.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"node_modules/.pnpm/geist@1.3.1_next@14.2.8_react-dom@18.3.1_react@18.3.1__react@18.3.1_/node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Aleo\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-aleo\"}],\"variableName\":\"aleo\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.8_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Ubuntu_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\",\"variable\":\"--font-ubuntu-mono\"}],\"variableName\":\"ubuntu_mono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/.pnpm/sonner@1.5.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Dock"] */ "/vercel/path0/src/components/dock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/src/components/header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
