'use client';
import { useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "./ui/dialog";
import { MessageSquarePlus, Bug, Lightbulb, MessageCircle, PartyPopperIcon } from "lucide-react";
import { toast } from "sonner";

type FeedbackType = 'bug' | 'suggestion' | 'feedback' | 'praise';

export function FeedbackModal({ userId }: { userId: string | null }) {
    const [feedback, setFeedback] = useState("");
    const [selectedType, setSelectedType] = useState<FeedbackType | null>(null);

    const feedbackTypes = [
        { type: 'bug' as FeedbackType, icon: Bug, label: 'Report Bug' },
        { type: 'suggestion' as FeedbackType, icon: Lightbulb, label: 'Suggestion' },
        { type: 'feedback' as FeedbackType, icon: MessageCircle, label: 'Feedback' },
        { type: 'praise' as FeedbackType, icon: PartyPopperIcon, label: 'Praise' },
    ];

    const handleSubmit = async () => {
        if (!feedback.trim() || !selectedType) return;

        try {
            const response = await fetch('/api/feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message: feedback,
                    userId,
                    type: selectedType,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to submit feedback');
            }

            toast.success("Thank you for your feedback!");
            setFeedback("");
            setSelectedType(null);
        } catch (error) {
            console.error('Error submitting feedback:', error);
            toast.error("Failed to submit feedback");
        }
    };

    return (
        <DialogContent className="bg-white">
            <DialogHeader>
                <DialogTitle>Share Your Feedback</DialogTitle>
                <DialogDescription>
                    Help us improve mmbl by sharing your thoughts, suggestions, or reporting issues.
                </DialogDescription>
            </DialogHeader>
            <div className="space-y-4 pt-4">
                <div className="flex gap-2">
                    {feedbackTypes.map(({ type, icon: Icon, label }) => (
                        <button
                            key={type}
                            onClick={() => setSelectedType(type)}
                            className={`flex-1 flex flex-col items-center gap-2 p-3 rounded-lg border transition-all ${
                                selectedType === type 
                                    ? 'border-orange-500 bg-orange-50 text-orange-700' 
                                    : 'border-gray-200 hover:border-orange-200'
                            }`}
                        >
                            <Icon className={`w-5 h-5 ${
                                selectedType === type ? 'text-orange-500' : 'text-gray-500'
                            }`} />
                            <span className="text-sm">{label}</span>
                        </button>
                    ))}
                </div>
                <textarea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Tell us what you think..."
                    className="w-full min-h-[150px] p-3 text-sm rounded-md border focus:outline-none focus:ring-2 focus:ring-orange-500 resize-none"
                />
                <div className="flex justify-end">
                    <button
                        onClick={handleSubmit}
                        disabled={!feedback.trim() || !selectedType}
                        className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Submit Feedback
                    </button>
                </div>
            </div>
        </DialogContent>
    );
}