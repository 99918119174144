"use client";
import { useState, useEffect } from 'react';
import { Calendar, CalendarDays, CalendarDaysIcon, CheckCheckIcon, CheckSquare, Circle, Clock, Square, Star, Trash2, } from 'lucide-react';
import { Pencil, Check, X } from "@phosphor-icons/react/dist/ssr"

import { format } from 'date-fns';
import { Task } from '~/server/db/schema';
import { hashStringToColor, getDarkerColor, hexToRgb } from '~/lib/utils';
import { motion, AnimatePresence } from "framer-motion";
import { CalendarSubscription } from './CalendarSubscription';

export function Tasks({ initialTasks, user }: {initialTasks: Task[], user: any}) {
    const [tasks, setTasks] = useState<Task[]>(initialTasks);
    const [editingTaskId, setEditingTaskId] = useState<string | null>(null);
    const [editedTags, setEditedTags] = useState<string[]>([]);
    const [availableTags, setAvailableTags] = useState<Tags[]>([]);

    const fetchTags = async () => {
        try {
            const response = await fetch(`/api/tags?userId=${user.id}`);
            if (response.ok) {
                const tags = await response.json();
                setAvailableTags(tags);
            }
        } catch (error) {
            console.error('Failed to fetch tags:', error);
        }
    };

    const handleEditTags = (taskId: string, currentTags: string[]) => {
        setEditingTaskId(taskId);
        setEditedTags(currentTags);
        fetchTags();
    };

    const handleTagToggle = (tagName: string) => {
        setEditedTags(prev => 
            prev.includes(tagName)
                ? prev.filter(t => t !== tagName)
                : [...prev, tagName]
        );
    };

    const handleSaveTags = async (taskId: string) => {
        try {
            const response = await fetch('/api/tasks', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    id: taskId, 
                    tags: editedTags 
                }),
            });
            if (response.ok) {
                setTasks(tasks.map(task => 
                    task.id === taskId ? { ...task, tags: editedTags } : task
                ));
                setEditingTaskId(null);
            }
        } catch (error) {
            console.error('Failed to update task tags:', error);
        }
    };

    useEffect(() => {
        const handleNewTasks = (event: CustomEvent<Task[]>) => {
            const newTasks = event.detail;
            if (newTasks && newTasks.length > 0) {
                setTasks(prevTasks => [...newTasks, ...prevTasks]);
            }
        };

        window.addEventListener('newTasks', handleNewTasks as EventListener);
        return () => {
            window.removeEventListener('newTasks', handleNewTasks as EventListener);
        };
    }, []);
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const { todaysTasks, pastTasks } = tasks.reduce((acc, task) => {
        // Skip deleted tasks
        if (task.deleted) {
            return acc;
        }

        const createdDate = task.createdAt ? new Date(task.createdAt) : new Date();
        createdDate.setHours(0, 0, 0, 0);
        const dueDate = task.dueDate ? new Date(task.dueDate) : null;
        
        // Task is considered for "Today's Tasks" if:
        // 1. It was completed today OR
        // 2. It has a due date today or in the future OR
        // 3. It has no due date and was created today
        const isCompletedToday = task.completed && 
            new Date(task.updatedAt).setHours(0, 0, 0, 0) === today.getTime();
        const isFutureOrTodayTask = dueDate ? 
            dueDate.getTime() >= today.getTime() : 
            createdDate.getTime() === today.getTime();

        if (isCompletedToday || isFutureOrTodayTask) {
            acc.todaysTasks.push(task);
        } else if (!task.completed) {
            acc.pastTasks.push(task);
        }
        return acc;
    }, { todaysTasks: [] as Task[], pastTasks: [] as Task[] });

    // Sort past tasks by due date (if available) or created date
    const sortedPastTasks = [...pastTasks].sort((a, b) => {
        const getCompareDate = (task: Task) => {
            return task.dueDate ? new Date(task.dueDate) : new Date(task.createdAt);
        };
        return getCompareDate(b).getTime() - getCompareDate(a).getTime();
    });


      const toggleTask = async (taskId: string, completed: boolean) => {
        // Optimistically update the UI
        setTasks(tasks.map(task => 
            task.id === taskId ? { ...task, completed: !completed } : task
        ));

        try {
            const response = await fetch('/api/tasks', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: taskId, completed: !completed }),
            });
            
            if (!response.ok) {
                // If the request fails, revert the optimistic update
                setTasks(tasks.map(task => 
                    task.id === taskId ? { ...task, completed } : task
                ));
                console.error('Failed to update task');
            }
        } catch (error) {
            // If there's an error, revert the optimistic update
            setTasks(tasks.map(task => 
                task.id === taskId ? { ...task, completed } : task
            ));
            console.error('Failed to update task:', error);
        }
    };

    const deleteTask = async (taskId: string) => {
        try {
            const response = await fetch('/api/tasks', {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: taskId }),
            });
            if (response.ok) {
                setTasks(tasks.map(task => 
                    task.id === taskId 
                        ? { ...task, deleted: true }
                        : task
                ));
            }
        } catch (error) {
            console.error('Failed to delete task:', error);
        }
    };

    const renderTaskList = (taskList: Task[], title: string, todaysTasks?: boolean) => {
        return (
            <div className="space-y-4">
                <div className='flex flex-row justify-between'>
                    <div className='flex w-full items-center space-x-2 justify-between'>
                        <div className='flex items-center space-x-2'>
                            {!todaysTasks && <CalendarDays className="w-5 h-5 text-gray-400" />}
                            {todaysTasks && <Calendar className="w-5 h-5 text-gray-400" />}
                            <h2 className="text-xl font-semibold font-aleo">{title}</h2>
                        </div>
                        {todaysTasks && <CalendarSubscription calendarToken={user?.calendarToken} />}
                    </div>
                </div>
                <AnimatePresence initial={false}>
                {taskList.length === 0 && (
                    <p className="text-gray-500">No tasks yet</p>
                )}

                {taskList.map((task) => (
                        <motion.div
                            key={task.id}
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <div
                                className={`flex items-center justify-between p-4 rounded-lg border ${
                                    task.completed ? 'bg-neutral-50' : 'bg-white'
                                }`}
                    >
                        <div className="flex space-x-3 w-full items-start">
                            <button
                                onClick={() => toggleTask(task.id, task.completed)}
                                className="focus:outline-none"
                                pirsch-event="Toggle task completion" 
                            >
                                {task.completed ? (
                                    <CheckSquare className="w-5 h-5 text-orange-500" />
                                ) : (
                                    <Square className="w-5 h-5 text-gray-400 hover:text-orange-400 transition-colors" />
                                )}
                            </button>
                            <div className='w-full gap-1 flex flex-col'>
                                <p className={`${task.completed ? 'line-through text-gray-500' : ''} text-sm`}>
                                    {task.title}
                                </p>
                                <div className="flex flex-row justify-between">
                                    {task.dueDate && (
                                        <p className="text-xs text-gray-500 flex items-center">
                                            <CalendarDaysIcon className="w-3 h-3 inline mr-1" />
                                            {format(new Date(task.dueDate), 'MMM d, yyyy h:mm a')}
                                        </p>
                                    )}
                                    {task.estimatedMinutes > 0 && (
                                        <p className="text-xs text-gray-500 flex items-center ml-2">
                                            <Clock className="w-3 h-3 inline mr-1" />
                                            {task.estimatedMinutes >= 60 
                                                ? `${Math.floor(task.estimatedMinutes / 60)} hr${Math.floor(task.estimatedMinutes / 60) > 1 ? 's' : ''}` 
                                                : `${task.estimatedMinutes} min`}
                                        </p>
                                    )}
                                </div>
                                <div className="flex flex-row justify-between w-full">
                                    <div className="flex flex-wrap gap-2 mt-1">
                                        {editingTaskId === task.id ? (
                                            <div className="flex flex-wrap gap-2">
                                                {availableTags.map((tag) => {
                                                    const bgColor = hashStringToColor(tag.name);
                                                    const textColor = getDarkerColor(bgColor);
                                                    const isSelected = editedTags.includes(tag.name);
                                                    return (
                                                        <button
                                                            key={tag.id}
                                                            pirsch-event="Add tag to task"
                                                            onClick={() => handleTagToggle(tag.name)}
                                                            className={`capitalize items-center flex flex-row rounded-full px-2 py-0.5 text-xs transition-all font-ubuntu-mono ${
                                                                isSelected ? 'ring-2 ring-offset-2' : 'opacity-50'
                                                            }`}
                                                            style={{ 
                                                                backgroundColor: bgColor, 
                                                                color: textColor,
                                                                '--tw-ring-color': `rgb(${hexToRgb(bgColor).join(', ')}, 0.5)`
                                                            }}
                                                        >
                                                            {isSelected ? (
                                                                <Check className="w-3 h-3 mr-1" />
                                                            ) : (
                                                                <Circle className="w-3 h-3 mr-1" />
                                                            )}
                                                            {tag.name}
                                                        </button>
                                                    );
                                                })}
                                                <div className="flex gap-1 ml-2">
                                                    <button pirsch-event="Save task tags" onClick={() => handleSaveTags(task.id)} className="text-green-500">
                                                        <Check className="w-4 h-4" />
                                                    </button>
                                                    <button pirsch-event="Cancel editing task tags" onClick={() => setEditingTaskId(null)} className="text-red-500">
                                                        <X className="w-4 h-4" />
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {task.tags?.map((tag) => {
                                                    const bgColor = hashStringToColor(tag);
                                                    const textColor = getDarkerColor(bgColor);
                                                    const rgb = hexToRgb(bgColor);
                                                    return (
                                                        <span
                                                            key={tag}
                                                            className="capitalize items-center flex flex-row rounded-full px-2 py-0.5 text-xs font-ubuntu-mono"
                                                            style={{ 
                                                                backgroundColor: bgColor, 
                                                                color: textColor,
                                                                '--tw-ring-color': `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.5)`
                                                            }}
                                                        >
                                                            <Circle className="w-2 h-2 inline mr-1" style={{fill: textColor}} />
                                                            {tag}
                                                        </span>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </div>
                                    <div className="flex gap-2">
                                        {!editingTaskId && (
                                            <button 
                                                pirsch-event="Edit task"
                                                onClick={() => handleEditTags(task.id, task.tags ?? [])}
                                                className="text-gray-400 hover:text-gray-600"
                                            >
                                                <Pencil className="w-4 h-4" />
                                            </button>
                                        )}
                                        <button
                                            pirsch-event="Delete task"
                                            onClick={() => deleteTask(task.id)}
                                            className="text-gray-400 hover:text-red-500"
                                        >
                                            <Trash2 className="w-4 h-4" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </motion.div>
                ))}
                </AnimatePresence>
            </div>
        );
    };

    return (
        <div className="space-y-8 bg-neutral-50 rounded-lg p-4">
            {renderTaskList(todaysTasks, "Upcoming Tasks", true)}
            {sortedPastTasks.length > 0 && renderTaskList(sortedPastTasks, "Overdue")}
        </div>
    );
}