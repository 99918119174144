import {
  Microphone,
  Tag,
  Lock,
  Robot,
  Crown,
  ArrowDown,
} from "@phosphor-icons/react/dist/ssr";

import { Heart, ListChecks } from "lucide-react";
import { TypeAnimation } from "react-type-animation";
import Testimonials from "./testimonials";
import NumberFlow from "@number-flow/react";
import { useEffect, useState } from "react";
import { ShimmerButton } from "./ui/shimmer-button";
import { UseCases } from "./usecases";
import Video from "./video";

export default function Landing({
  userCount: userCount,
}: {
  userCount: number;
}) {
  const [displayedUserCount, setUserCount] = useState(0);

  useEffect(() => {
    const value = userCount + 130;
    setUserCount(value);
  }, [userCount]);

  return (
    <div className="mb-16 flex flex-col items-center p-2 px-8 md:p-4">
      <div className="md:mt-16 mt-8 w-full max-w-2xl">
        <div className="mx-auto max-w-lg">
          <h1 className="mb-4 text-center text-3xl font-bold">
            Turn your spoken thoughts into scheduled & organised notes & tasks
          </h1>

          <div className="mb-4 flex items-center gap-5">
            <p className="text-sinc-700 text-center text-sm">
              mmbl automatically transcribes, organises, and tags your voice
              notes so you can focus on getting stuff done instead of juggling
              sticky notes.
            </p>
          </div>

          <ShimmerButton
            onClick={(e) => {
              e.preventDefault();
              const element = document.getElementById("try-mmbl");
              if (element) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
                setTimeout(() => {
                  window.dispatchEvent(new CustomEvent("scrolledToRecorder"));
                }, 500);
              }
            }}
            shimmerColor="orange"
            className="mx-auto mb-4 gap-2 flex flex-row items-center text-sm"
          >
            <Microphone className="h-5 w-5 flex-shrink-0" /> Try mmbl - no sign
            up needed!
          </ShimmerButton>

          <div className="text-natural-500 text-center mb-12 text-sm font-medium">
            <Heart className="h-4 fill-red-400 w-4 flex-shrink-0 inline-block" /><span className="sr-only">Loved</span> by <NumberFlow value={displayedUserCount} /> wonderful
            note-takers
          </div>

          <Video />

          <h2 className="text-3xl md:text-xl mb-6 mt-16 font-bold text-center">What does mmbl do?</h2>
          <div className="mb-8 flex items-center gap-5">
            <Microphone className="h-6 w-6 flex-shrink-0" />
            <div>
              <h2 className="mb-1 text-sm font-semibold">
                Clean & Clear Notes
              </h2>
              <p className="text-sinc-700 text-sm">
                Speak naturally and mmbl transforms your ramblings into well-structured, clear notes and tasks. No need to worry about perfect phrasing - we'll clean it up for you.
              </p>
            </div>
          </div>

          <div className="mb-8 flex items-center  gap-5">
            <Tag className="h-6 w-6 flex-shrink-0" />
            <div>
              <h2 className="mb-1 text-sm font-semibold">
                Auto-Tagging & Smart Organisation
              </h2>
              <p className="text-sinc-700 text-sm">
                Define your own tags, and let mmbl do the categorising. Whether
                it's a blog post idea, a dentist appointment update, or a random
                thought, you'll find it all automatically sorted.
              </p>
            </div>
          </div>

          <div className="mb-8 flex items-center gap-5">
            <ListChecks className="h-6 w-6 flex-shrink-0" />
            <div>
              <h2 className="mb-1 text-sm font-semibold">
                Task Detection & Calendar Sync
              </h2>
              <p className="text-sinc-700 text-sm">
                Say <span className="italic">"I need to buy groceries on Friday at 6"</span> and mmbl spots the
                task, sets a due date, adds tags and syncs with your calendar. No extra
                steps—just talk and done.
              </p>
            </div>
          </div>

          <div className="mb-8 flex items-center gap-5">
            <Lock className="h-6 w-6 flex-shrink-0" />
            <div>
              <h2 className="mb-1 text-sm font-semibold">
                Your Thoughts, Your Privacy
              </h2>
              <p className="text-sinc-700 text-sm">
                Your voice notes and data stay yours. No recordings are ever
                stored on our servers, and we keep your transcriptions secure.
              </p>
            </div>
          </div>

          <div className="mb-8 flex items-center gap-5">
            <Robot className="h-6 w-6 flex-shrink-0" />
            <div>
              <h2 className="mb-1 text-sm font-semibold">Ask mmbl for help</h2>
              <p className="text-sinc-700 text-sm">
                Need a summary of a past note? Want a quick email draft from
                your recordings? mmbl can handle the busywork so you can stay in
                your flow.
              </p>
            </div>
          </div>

          <div className="mb-8 flex items-center gap-5">
            <Crown className="h-6 w-6 flex-shrink-0" />
            <div className="w-full">
              <h2 className="mb-1 bg-gradient-to-r from-orange-400 to-pink-600 bg-clip-text text-sm font-semibold text-transparent">
                Free to try - no credit card required
              </h2>
              <p className="text-sinc-700 text-sm">
                Try the core features of mmbl for free, no credit card or
                sign-up required.
              </p>
            </div>
          </div>
        </div>

        <div className="my-12">

        <h2 className="text-3xl md:text-xl mb-6 mt-16 font-bold text-center">How much does mmbl cost?</h2>
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 max-w-4xl mx-auto">
                {/* Free Plan */}
                <div className="relative w-full cursor-pointer overflow-hidden rounded-xl border p-6 border-gray-950/[.1] bg-gray-950/[.01]">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-medium">Free</h3>
                    <p className="text-lg font-bold">$0<span className="text-sm font-normal text-zinc-600">/forever</span></p>
                  </div>
                  <ul className="space-y-2 mb-4 text-sm text-gray-600 dark:text-white/70">
                    <li>✓ 15 tasks & notes</li>
                    <li>✓ Unlimited tags</li>
                    <li>✓ Automatic tagging</li>
                    <li>✓ Calendar sync</li>
                    <li>✓ Share notes with anyone</li>
                  </ul>
                </div>

                {/* Premium Plan */}
                <div className="relative w-full cursor-pointer overflow-hidden rounded-xl border p-6 border-gray-950/[.1] bg-orange-50">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-2">
                      <h3 className="text-lg font-medium">Premium</h3>
                      <span className="bg-orange-500 text-white text-xs px-2 py-1 rounded-full">Popular</span>
                    </div>
                    <p className="text-lg font-bold">$4.99<span className="text-sm font-normal text-zinc-600">/month</span></p>
                  </div>
                  <ul className="space-y-2 mb-4 text-sm text-gray-600 dark:text-white/70">
                    <li>✓ Everything in Free, plus:</li>
                    <li>✓ Unlimited tasks & notes</li>
                    <li>✓ AI assistance & summaries</li>
                    <li>✓ Priority support</li>
                  </ul>
                </div>

                {/* Lifetime Plan - Full Width */}
                <div className="relative w-full cursor-pointer overflow-hidden rounded-xl border p-6 border-gray-950/[.1] bg-gradient-to-r from-orange-50 to-pink-50 md:col-span-2">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-2">
                      <h3 className="text-lg font-medium">Lifetime <span className="hidden md:inline">Access</span></h3>
                      <span className="bg-gradient-to-r from-orange-500 to-pink-500 text-white text-xs px-2 py-1 rounded-full">Limited Time<span className="hidden md:inline"> Offer</span></span>
                    </div>
                    <p className="text-lg font-bold">$49.99<span className="text-sm font-normal text-zinc-600"> one-time</span></p>
                  </div>
                  <ul className="space-y-2 mb-4 text-sm text-gray-600 dark:text-white/70">
                    <li>✓ All Premium features forever</li>
                  </ul>
                </div>
              </div>
        </div>

        <div className="max-w-lg mx-auto">
          <UseCases />
        </div>

        <div className="relative my-12 flex w-full flex-col items-center justify-center overflow-hidden rounded-lg">
        <h2 className="text-3xl md:text-xl mb-6 font-bold text-center">Loved by note-takers</h2>
          <Testimonials />
          <div className="mt-12 min-w-[200px] max-w-[250px] text-center font-bold md:w-full md:min-w-full">
            Try mmbl without signing up!
            <br />
            <br />
            <TypeAnimation
              sequence={[
                '"I need to buy groceries at 5pm and take the trash out at 8pm"',
                2000,
                '"Tomorrow at 10am I have a meeting with the design team"',
                2000,
                '"Note for my blog: Three key insights from today\'s team retrospective..."',
                2000,
                '"At 5pm today I need to book a restaurant for dinner and take the trash out at 8pm"',
                2000,
                '"Shopping list for weekend BBQ: burgers, corn, and charcoal"',
                2000,
                '"Quick thought: We should add dark mode to the product dashboard"',
                2000,
              ]}
              wrapper="span"
              speed={70}
              className="block text-center text-xs font-medium"
              repeat={Infinity}
            />
            <div className="mt-8 flex justify-center" id="try-mmbl">
              <ArrowDown className="h-8 w-8 animate-bounce" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
