"use client"

import { useState, useEffect } from 'react';
import { Check, Square } from "lucide-react";
import { PartyPopperIcon } from "lucide-react";
import { Note as NoteType } from "~/server/db/schema";
import { Task as TaskType } from "~/server/db/schema";
import { Tags } from "~/server/db/schema";
import { motion, AnimatePresence } from "framer-motion";

interface OnboardingTask {
  id: string;
  title: string;
  description: string;
  completed: boolean;
}

export default function Onboarding({
    initialNotes,
    initialTasks,
    initialTags,
    onComplete
  }: {
    initialNotes: NoteType[],
    initialTasks: TaskType[],
    initialTags: Tags[],
    onComplete: () => void
  }) {
    const [tasks, setTasks] = useState<OnboardingTask[]>([
      {
        id: 'task',
        title: 'Capture your daily tasks',
        description: 'Speak your tasks into existence - it\'s like magic!',
        completed: initialTasks.length > 0
      },
      {
        id: 'note',
        title: 'Create a clean voice note',
        description: 'Capture your thoughts faster than ever before, just mumble!',
        completed: initialNotes.length > 0
      },
      {
        id: 'tag',
        title: 'Create a custom tag',
        description: 'Organise your way with personalised tags. Your tags are automatically get applied to your tasks and notes.',
        completed: initialTags.length > 0
      }
    ]);
  
    const [showCongrats, setShowCongrats] = useState(false);
  
    useEffect(() => {
        setTasks(prevTasks => ([
          { ...prevTasks[0], completed: initialTasks.length > 0 },
          { ...prevTasks[1], completed: initialNotes.length > 0 },
          { ...prevTasks[2], completed: initialTags.length > 0 }
        ]));
      }, [initialNotes, initialTasks, initialTags]); 
  
    // Check for completion
    useEffect(() => {
      if (tasks.every(task => task.completed)) {
        setShowCongrats(true);
        setTimeout(() => {
          onComplete();
        }, 2000);
      }
    }, [tasks, onComplete]);
  
    return (
      <div className="flex flex-col bg-black text-white rounded-lg p-6 mt-8 mb-4 justify-center gap-4 shadow-lg">
        <div className='flex flex-row items-center gap-4'>
          <PartyPopperIcon className="text-orange-500 w-8 h-8" />
          <h1 className="text-3xl font-bold">
            {showCongrats ? "You're all set! 🎉" : "mmbl your way to clarity"}
          </h1>
        </div>
        
        <p className="text-gray-300 text-sm">
          {showCongrats 
            ? "You've mastered the basics! Keep exploring and creating..."
            : "Complete these quick actions and see what mmbl can do"}
        </p>
        
        {!showCongrats && (
          <div className="flex flex-col gap-3">
            {tasks.map((task) => (
              <div key={task.id} className="flex items-center gap-3">
                {task.completed ? (
                  <Check className="w-6 h-6 text-green-600" />
                ) : (
                  <Square className="w-6 h-6 text-white" />
                )}
                <div className="flex flex-col">
                  <h3 className="font-semibold">{task.title}</h3>
                  <p className="text-xs text-gray-400">{task.description}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }