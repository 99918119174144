import Marquee from "./ui/marquee";

export default function Testimonials() {
    return (
        <div>
            <Marquee pauseOnHover className="[--duration:20s]">
                {reviews.map((review) => ( 
                    <ReviewCard key={review.username} {...review} />
                ))}
            </Marquee>

            <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
            <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
        </div>
    )
}

const ReviewCard = ({
    img,
    name,
    username,
    body,
  }: {
    img: string;
    name: string;
    username: string;
    body: string;
  }) => {
    return (
      <figure className="relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4 border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]">
        <div className="flex flex-row items-center gap-2">
          <img className="rounded-full" width="32" height="32" alt="" src={img} />
          <div className="flex flex-col">
            <figcaption className="text-sm font-medium dark:text-white">
              {name}
            </figcaption>
            <p className="text-xs font-medium dark:text-white/40">{username}</p>
          </div>
        </div>
        <blockquote className="mt-2 text-sm">{body}</blockquote>
      </figure>
    );
  };

const reviews = [
    {
      name: "Sarah Chen",
      username: "product manager",
      body: "Transformed how I capture ideas for my blog. Auto-tagging is so accurate!",
      img: "https://avatar.vercel.sh/sarahc",
    },
    {
        name: "Jenna Hagen",
        username: "chef",
        body: "I blurt out my day while I make my breakfast and it's in my calendar. So good.",
        img: "https://avatar.vercel.sh/sarahc",
      },
    {
      name: "Rachel Boone",
      username: "developer",
      body: "Word vomiting my thoughts and having them organised is game-changing.",
      img: "https://avatar.vercel.sh/miker",
    },
    {
      name: "Dr. Emily Walsh",
      username: "physician",
      body: "Perfect for keeping track of quick thoughts and tasks.",
      img: "https://avatar.vercel.sh/drwalsh",
    },
    {
      name: "Tom Barrett",
      username: "designer",
      body: "Worth every penny of the Pro subscription. So damn easy to use.",
      img: "https://avatar.vercel.sh/tomb",
    },
    {
      name: "Priya Patel",
      username: "ux researcher",
      body: "Love how it automatically tags my to-do list.",
      img: "https://avatar.vercel.sh/priyap",
    },
    {
      name: "David Kim",
      username: "entrepreneur",
      body: "Started with the free tier and upgraded to Pro within 3 days.",
      img: "https://avatar.vercel.sh/davidk",
    },
    {
      name: "Ben Jacobs",
      username: "head of learning ops",
      body: "Just tried out mmbl, this is exactly what I wished siri would do well.",
      img: "https://avatar.vercel.sh/benja",
    }
  ];