import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
import { LogSnag } from '@logsnag/next/server';

import crypto from "crypto";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function hashStringToColor(str: string): string {
  str = str.toLowerCase();
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  return `hsl(${hue}, 70%, 90%)`;
}

export function getDarkerColor(color: string): string {
  const hsl = color.match(/\d+/g)?.map(Number) ?? [0, 0, 0];
  return `hsl(${hsl[0] ?? 0}, ${hsl[1] ?? 0}%, ${Math.max((hsl[2] ?? 0) - 50, 10)}%)`;
}

export function generateCalendarToken(): string {
  return crypto.randomBytes(32).toString("hex");
}

export async function trackEvent(eventName: string, icon: string, userId: string, type: string) {
  const logsnag = new LogSnag({
    token: process.env.LOGSNAG_TOKEN,
    project: 'mmbl',
  });

  await logsnag.track({
    channel: type,
    event: eventName,
    user_id: userId,
    icon: icon,
    notify: true
  });
}

export function hexToRgb(hsl: string): number[] {
    // Convert HSL to RGB
    const h = parseInt(hsl.match(/\d+/)?.[0] ?? "0");
    const s = 70;  // Fixed saturation from hashStringToColor
    const l = 90;  // Fixed lightness from hashStringToColor
    
    const c = (1 - Math.abs(2 * l / 100 - 1)) * s / 100;
    const x = c * (1 - Math.abs((h / 60) % 2 - 1));
    const m = l / 100 - c / 2;
    
    let r = 0, g = 0, b = 0;
    if (h < 60) { r = c; g = x; }
    else if (h < 120) { r = x; g = c; }
    else if (h < 180) { g = c; b = x; }
    else if (h < 240) { g = x; b = c; }
    else if (h < 300) { r = x; b = c; }
    else { r = c; b = x; }
    
    return [
        Math.round((r + m) * 255),
        Math.round((g + m) * 255),
        Math.round((b + m) * 255)
    ];
}
