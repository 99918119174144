import type { Note } from "~/server/db/schema";
import { Share, Pencil, Trash, Check, X } from "@phosphor-icons/react/dist/ssr"
import { useState, useEffect } from "react";
import { format } from 'date-fns';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "~/components/ui/alert-dialog";
  import { toast } from "sonner";
  import { hashStringToColor, getDarkerColor, hexToRgb } from "~/lib/utils";
import { Circle } from "lucide-react";
import Link from "next/link";
import { Tags } from "~/server/db/schema";

export function Note({ note }: { note: Note }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedTitle, setEditedTitle] = useState(note.title ?? '');
    const [editedContent, setEditedContent] = useState(note.content ?? '');
    const [editedTags, setEditedTags] = useState<string[]>(note.tags ?? []);
    const [availableTags, setAvailableTags] = useState<Tags[]>([]);

    // Fetch available tags when editing starts
    useEffect(() => {
        if (isEditing) {
            fetchTags();
        }
    }, [isEditing]);

    const fetchTags = async () => {
        try {
            const response = await fetch(`/api/tags?userId=${note.userId}`);
            if (response.ok) {
                const tags = await response.json();
                setAvailableTags(tags);
            }
        } catch (error) {
            console.error('Failed to fetch tags:', error);
        }
    };

    const handleTagToggle = (tagName: string) => {
        setEditedTags(prev => 
            prev.includes(tagName)
                ? prev.filter(t => t !== tagName)
                : [...prev, tagName]
        );
    };

    const formattedDate = note?.createdAt 
    ? format(new Date(note.createdAt), 'MM/d/yyyy')
    : 'Date not available';

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            const response = await fetch('/api/notes', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: note.id,
                    title: editedTitle,
                    content: editedContent,
                    tags: editedTags,
                }),
            });

            if (response.ok) {
                setIsEditing(false);
                toast.success('Note updated successfully');
                window.dispatchEvent(new CustomEvent("noteUpdated", { 
                    detail: { ...note, title: editedTitle, content: editedContent, tags: editedTags } 
                }));
            } else {
                toast.error('Failed to update note');
            }
        } catch (error) {
            console.error('Error updating note:', error);
            toast.error('Error updating note');
        }
    };

    const handleCancel = () => {
      setIsEditing(false);
      setEditedTitle(note.title ?? '');
      setEditedContent(note.content ?? '');
  };

    const handleShare = () => {
        const noteUrl = `${window.location.origin}/n/${note.id}`;
        navigator.clipboard.writeText(noteUrl).then(() => {
            toast.success('Note URL copied to clipboard');
        }).catch((err) => {
            console.error('Failed to copy: ', err);
            toast.error('Failed to copy URL');
        });
    };

    return (
        <div className="rounded-lg flex flex-col py-4 pr-4 px-2 transition-all hover:bg-neutral-50 gap-1">
            {isEditing ? (
                <>
                    <input
                        type="text"
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        className="text-base font-medium mb-2 p-1 border rounded"
                    />
                    <textarea
                        value={editedContent}
                        onChange={(e) => setEditedContent(e.target.value)}
                        className="text-sm text-gray-500 whitespace-pre-wrap p-1 border rounded"
                        rows={8}
                    />
                    <div className="flex flex-wrap gap-2 mt-3">
                        {availableTags.map((tag) => {
                            const bgColor = hashStringToColor(tag.name);
                            const textColor = getDarkerColor(bgColor);
                            const isSelected = editedTags.includes(tag.name);
                            return (
                                <button
                                    key={tag.id}
                                    onClick={() => handleTagToggle(tag.name)}
                                    pirsch-event="Note tag editing"
                                    className={`capitalize items-center flex flex-row rounded-full px-2 py-0.5 text-xs transition-all font-ubuntu-mono ${
                                        isSelected ? 'ring-2 ring-offset-2' : 'opacity-50'
                                    }`}
                                    style={{ 
                                        backgroundColor: bgColor, 
                                        color: textColor,
                                        '--tw-ring-color': `rgb(${hexToRgb(bgColor).join(', ')}, 0.5)`
                                    }}
                                >
                                    {isSelected ? (
                                        <Check className="w-3 h-3 mr-1" />
                                    ) : (
                                        <Circle className="w-3 h-3 mr-1" />
                                    )}
                                    {tag.name}
                                </button>
                            );
                        })}
                    </div>
                </>
            ) : (
                <>
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-base font-medium font-funnel-sans">{note.title}</h2>
                      <p className="text-xs text-zinc-400">{formattedDate}</p>
                    </div>
                    <p className="text-sm text-gray-500 whitespace-pre-wrap font-funnel-sans">{note.content}</p>
                </>
            )}
            <div className="flex justify-between">
                <div className="flex flex-wrap gap-2 mt-3">
                {note.tags?.map((tag) => {
                    const bgColor = hashStringToColor(tag);
                    const textColor = getDarkerColor(bgColor);
                    const rgb = hexToRgb(bgColor);
                    return (
                        <Link
                            key={tag}
                            href={`/tags/${encodeURIComponent(tag)}`}
                            className="capitalize items-center flex flex-row rounded-full px-2 py-0.5 text-xs transition-all font-ubuntu-mono hover:ring-2 hover:ring-offset-2"
                            style={{ 
                                backgroundColor: bgColor, 
                                color: textColor,
                                '--tw-ring-color': `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.5)`
                            }}
                        >
                            <Circle className="w-2 h-2 inline mr-1" style={{fill: textColor}} />
                            {tag}
                        </Link>
                    );
                })}
                </div>
                <div className="flex gap-1">
                  
                      {isEditing ? (
                          <>
                              <button 
                                  className="text-sm text-gray-400"
                                  onClick={handleSave}
                                  pirsch-event="Save note editing"
                              >
                                  <Check className="w-5 h-5 md:w-4 md:h-4 hover:text-green-500" />
                                  <span className="sr-only">Save</span>
                              </button>
                              <button 
                                  className="text-sm text-gray-400"
                                  onClick={handleCancel}
                                  pirsch-event="Cancel note editing"
                              >
                                  <X className="w-5 h-5 md:w-4 md:h-4 hover:text-red-500" />
                                  <span className="sr-only">Cancel</span>
                              </button>
                          </>
                      ) : (
                        <div className="flex gap-1">
                          <button 
                              className="text-sm text-gray-400"
                              onClick={handleEdit}
                              pirsch-event="Edit note"
                          >
                              <Pencil className="w-5 h-5 md:w-4 md:h-4 hover:text-gray-800" />
                              <span className="sr-only">Edit</span>
                          </button>
                          <button 
                            className="text-sm text-gray-400"
                              onClick={handleShare}
                              pirsch-event="Share note"
                          >
                              <Share className="w-5 h-5 md:w-4 md:h-4 hover:text-gray-800" />
                              <span className="sr-only">Share</span>
                          </button>
                          <DeleteNoteDialog note={note} />
                          </div>
                      )}
                </div>
            </div>
        </div>
    )
}

function DeleteNoteDialog({ note }: { note: Note }) {
    const handleDelete = async () => {
        try {
          const response = await fetch('/api/notes', {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: note.id }),
          });
          
          if (response.ok) {
            window.dispatchEvent(new CustomEvent("noteDeleted", { detail: note }));
            toast.success('Note deleted successfully');
          } else {
            console.error('Failed to delete note');
          }
        } catch (error) {
          console.error('Error deleting note:', error);
        }
      };

    return (
        <AlertDialog>
          <AlertDialogTrigger asChild>
          <button className="text-sm text-gray-400" pirsch-event="Delete note">
                <Trash className="w-5 h-5 md:w-4 md:h-4 hover:text-red-500" />
                <span className="sr-only">Delete</span>
            </button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Delete Note</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete your note.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleDelete} className="bg-red-500 hover:bg-red-600 text-white">Delete</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
    )
}