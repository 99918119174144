'use client';

import Link from "next/link"
import { Input } from "./ui/input"
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "./ui/dropdown-menu"
import { Sparkle, Spinner, Note as NoteIcon, QuestionMark, Robot } from "@phosphor-icons/react/dist/ssr"
import { Button } from "./ui/button"
import { useState, useEffect, useRef } from 'react';
import { useDebounce } from '~/hooks/use-debounce';
import { AnimatePresence, motion } from "framer-motion";
import { MessageSquarePlus, Shield, ScrollText } from "lucide-react";
import { FeedbackModal } from "./FeedbackModal";
import { Dialog, DialogTrigger } from "./ui/dialog";

export function Header({user: user}: any) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasCompletedSearch, setHasCompletedSearch] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const searchInputRef = useRef(null);
  const resultsRef = useRef(null);
  const searchContainerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const debouncedQuery = useDebounce(query, 300);

  const handleSearch = async (searchQuery: string) => {
    if (!user?.id || !searchQuery) {
      setResults([]);
      setHasCompletedSearch(false);
      return;
    }

    setIsLoading(true);
    setHasCompletedSearch(false);

    try {
      const response = await fetch(`/api/search?userId=${user.id}&query=${encodeURIComponent(searchQuery)}`);
      if (!response.ok) {
        throw new Error('Search failed');
      }
      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error('Error performing search:', error);
      setResults([]);
    } finally {
      setIsLoading(false);
      setHasCompletedSearch(true);
    }
  };

  useEffect(() => {
    handleSearch(debouncedQuery);
  }, [debouncedQuery]);

  useEffect(() => {
    if (hasCompletedSearch && results.length > 0) {
      setFocusedIndex(0);
    } else {
      setFocusedIndex(-1);
    }
  }, [hasCompletedSearch, results]);

  const handleBlur = (e: React.FocusEvent) => {
    // Check if the new focus target is outside of the search container
    if (!searchContainerRef.current.contains(e.relatedTarget)) {
      setHasCompletedSearch(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!hasCompletedSearch || results.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex + 1) % (results.length + 1));
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex - 1 + results.length + 1) % (results.length + 1));
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedIndex >= 0 && focusedIndex < results.length) {
          window.location.href = `/n/${results[focusedIndex].id}`;
        } else if (focusedIndex === results.length) {
          // Handle "Ask AI" action
        }
        break;
      case 'Escape':
        e.preventDefault();
        searchInputRef.current?.focus();
        setHasCompletedSearch(false);
        break;
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        searchInputRef.current?.focus();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <header className="fixed top-0 z-50 w-full">
      <div className="flex items-center justify-between h-16 px-4 md:px-6 supports-backdrop-blur:bg-white/10 supports-backdrop-blur:dark:bg-black/10 backdrop-blur-md">
        <Link href="/" className="flex items-center gap-2" prefetch={false}>
            <span className="hidden md:block text-xl font-bold">mmbl</span>
            <span className="sr-only">Mumble Voice Notes</span>
        </Link>
        <div className="w-full mx-auto flex items-center justify-center gap-4">
          <div ref={searchContainerRef} className="relative flex-1 max-w-md" onKeyDown={handleKeyDown}>
            <div className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5">
              {isLoading ? (
                <Spinner className={`w-5 h-5 animate-spin transition-opacity}`} />
              ) : (
                <SearchIcon className="w-5 h-5" />
              )}
            </div>
            <Input 
              ref={searchInputRef}
              type="search" 
              placeholder="Search notes with natural language (⌘+K)" 
              className="w-full pl-10 rounded-full bg-zinc-50 placeholder:text-zinc-400 active:text-zinc-700 focus-visible:bg-zinc-100 transition-colors focus-visible:ring-2 focus-visible:ring-orange-300"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onBlur={handleBlur}
            />
              <AnimatePresence>
            {hasCompletedSearch && (
              <motion.div 
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                ref={resultsRef} 
                className="absolute bg-white p-4 shadow-lg rounded-xl rounded-t-none left-0 w-full mt-2"
              >
                {results.length > 0 ? (
                  <ul 
                    className="py-2 flex flex-col gap-2">
                    {results.map((note, index) => (
                      <a
                        href={`/n/${note.id}`}
                        key={index}
                        className={`px-3 py-3 flex flex-row gap-4 rounded-xl hover:bg-gray-100 cursor-pointer items-center ${
                          index === focusedIndex ? 'bg-gray-100' : ''
                        }`}
                        onMouseEnter={() => setFocusedIndex(index)}
                      >
                        <div className="bg-white border border-gray-200 rounded-xl p-2 w-12 h-12 items-center flex justify-center">
                          <NoteIcon className="w-6 h-6" />
                        </div>
                        <div className="flex flex-col gap-1">
                          <span className="font-medium text-xs">{note.title}</span>
                          <span className="line-clamp-2 text-zinc-500 text-sm">{note.contentPreview}</span>
                        </div>
                      </a>
                    ))}
                    {false && <li
                      className={`px-3 py-3 flex flex-row gap-4 group rounded-xl hover:bg-gray-100 cursor-pointer items-center ${
                        focusedIndex === results.length ? 'bg-gray-100' : ''
                      }`}
                      onMouseEnter={() => setFocusedIndex(results.length)}
                    >
                      <div className="bg-black text-white group-hover:text-orange-400 border border-gray-200 rounded-xl p-2 w-12 h-12 items-center flex justify-center">
                        <Sparkle className="w-6 h-6" />
                      </div>
                      <div className="flex flex-col font-medium gap-1">
                        Ask AI about your query...
                      </div>
                    </li>}
                  </ul>
                ) : (
                  <motion.div 
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="py-4 text-center text-gray-500">
                    No results found for "{query}"
                  </motion.div>
                )}
              </motion.div>
            )}
            </AnimatePresence>
          </div>
        </div>
          <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" className="rounded-full">
                <MenuIcon className="w-6 h-6" />
                <span className="sr-only">Toggle navigation</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-white" align="end">
              <DropdownMenuItem className="cursor-pointer" onSelect={() => {
                setIsOpen(true);
                setIsDropdownOpen(false);
              }}>
                <MessageSquarePlus className="w-4 h-4 mr-2" />
                Provide Feedback
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link href="/privacy" prefetch={true} className="flex items-center">
                  <Shield className="w-4 h-4 mr-2" />
                  Privacy Policy
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link href="/terms" prefetch={true} className="flex items-center">
                  <ScrollText className="w-4 h-4 mr-2" />
                  Terms & Conditions
                </Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <FeedbackModal userId={user?.id || null} />
          </Dialog>
      </div>
    </header>
  )
}

function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  )
}

function SearchIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8" />
      <path d="m21 21-4.3-4.3" />
    </svg>
  )
}
