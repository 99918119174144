import { Crown } from "lucide-react"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "./ui/dialog"

export function PremiumModal({ userId }: { userId: string }) {
    return (
        <Dialog>
            <DialogTrigger><span className="underline font-semibold cursor-pointer"  pirsch-event="Open Premium Modal" >Upgrade to Premium</span></DialogTrigger>
            <DialogContent className="bg-white">
                <DialogHeader>
                    <DialogTitle className="flex flex-row items-center gap-2">
                        <Crown size={28} className="text-orange-500" />
                        Upgrade your account to premium
                    </DialogTitle>
                    <DialogDescription className="pt-4">
                        <div className="space-y-4">
                            <p className="text-zinc-600">
                                Take your experience to the next level with premium features and unlimited access.
                            </p>
                            
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4">
                                {/* Monthly Plan */}
                                <div className="border rounded-lg p-4 space-y-3 hover:shadow-md transition-shadow">
                                    <h3 className="font-semibold text-lg">Monthly Premium</h3>
                                    <p className="text-2xl font-bold">$4.99<span className="text-sm font-normal text-zinc-600">/month</span></p>
                                    <ul className="space-y-2 text-sm text-zinc-600 pb-8">
                                        <li>✓ Unlimited transcription</li>
                                        <li>✓ Unlimited task generation</li>
                                        <li>✓ Unlimited note creation</li>
                                        <li>✓ Cancel anytime</li>
                                    </ul>
                                    <a href={`https://mmbl.lemonsqueezy.com/buy/aeabf2d2-6856-4ec0-a5e2-747e440dac2f?checkout[custom][user_id]=${userId}`} >
                                        <button pirsch-event="Subscribe Monthly Clicked" className="w-full bg-orange-500 text-white rounded-md py-2 mt-2 hover:bg-orange-600 transition-colors">
                                            Subscribe Monthly
                                        </button>
                                    </a>
                                    
                                </div>

                                {/* Lifetime Plan */}
                                <div className="border rounded-lg p-4 space-y-3 hover:shadow-md transition-shadow bg-orange-50">
                                    <div className="flex items-center gap-2">
                                        <h3 className="font-semibold text-lg">Lifetime Access</h3>
                                        <span className="bg-orange-500 text-white text-xs px-2 py-1 rounded-full">Best Value</span>
                                    </div>
                                    <p className="text-2xl font-bold">$49.99<span className="text-sm font-normal text-zinc-600"> one-time</span></p>
                                    <ul className="space-y-2 text-sm text-zinc-600 pb-8">
                                        <li>✓ Limited edition</li>
                                        <li>✓ All monthly features</li>
                                        <li>✓ Lifetime access</li>
                                        <li>✓ Future updates included</li>
                                    </ul>
                                    <a href={`https://mmbl.lemonsqueezy.com/buy/0a24134e-380e-4673-8abc-0c4ef8a235b0?checkout[custom][user_id]=${userId}`}>
                                        <button pirsch-event="Get Lifetime Access Clicked" className="w-full bg-orange-500 text-white rounded-md py-2 mt-2 hover:bg-orange-600 transition-colors">
                                           Get Lifetime Access
                                        </button>
                                    </a>
                                </div>
                            </div>

                            <p className="text-xs text-zinc-500 text-center pt-4">
                                Secure payment processed by LemonSqueezy. 100% satisfaction guaranteed.
                            </p>
                        </div>
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    )
}