"use client"

import { useState, useEffect } from "react";
import { Note as NoteType } from "~/server/db/schema";
import { Note as NoteComponent } from "./note";
import { motion, AnimatePresence } from "framer-motion";

import { NotebookPen } from "lucide-react";

export function Notes({ initialNotes, user, signInUrl }: { initialNotes: NoteType[], user: any, signInUrl: string }) {
    const [notes, setNotes] = useState<NoteType[]>(initialNotes);

    if (notes.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center py-12 text-center">
                <div className="text-gray-400 mb-4">
                    <NotebookPen className="w-12 h-12" />
                </div>
                <h3 className="text-lg font-medium mb-2">No notes found</h3>
                <p className="text-sm text-gray-500">
                    Try creating a new note or selecting a different tag
                </p>
            </div>
        );
    }

    useEffect(() => {
        const handleNewNote = (event: CustomEvent<NoteType>) => {
            const newNote = event.detail;
            if (newNote) { 
                setNotes(prevNotes => [newNote, ...prevNotes]);
            }
        };

        const handleNoteUpdated = (event: CustomEvent<NoteType>) => {
            const updatedNote = event.detail;
            setNotes(prevNotes => 
                prevNotes.map(note => 
                    note.id === updatedNote.id ? updatedNote : note
                )
            );
        };

        const handleNoteDeleted = (event: CustomEvent<NoteType>) => {
            const deletedNote = event.detail;
            setNotes(prevNotes => prevNotes.filter(note => note.id !== deletedNote.id));
        };

        window.addEventListener('newNote', handleNewNote as EventListener);
        window.addEventListener("noteUpdated", handleNoteUpdated as EventListener);
        window.addEventListener('noteDeleted', handleNoteDeleted as EventListener);
        return () => {
            window.removeEventListener('newNote', handleNewNote as EventListener);
            window.removeEventListener("noteUpdated", handleNoteUpdated as EventListener);
            window.removeEventListener('noteDeleted', handleNoteDeleted as EventListener);
        };
    }, []);

    return (
        <motion.div 
        className="flex flex-col mx-auto w-full max-w-2xl gap-4 p-4 "
        layout
    >
        <div className='flex items-center space-x-2 mt-4'>
            <NotebookPen className="w-5 h-5 text-gray-400" />
            <h2 className="text-xl font-semibold font-aleo">Notes</h2>
        </div>
        <div className="flex flex-col gap-4">
            <AnimatePresence initial={false}>
                {notes.map((note) => (
                    <motion.div
                        key={note.id}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <NoteComponent note={note} />
                    </motion.div>
                ))}
            </AnimatePresence>
        </div>
        </motion.div>
    );
}