'use client' // Error boundaries must be Client Components
 
import Link from 'next/link'
import { ArrowLeft, Cookie } from 'lucide-react'
 
export default function ErrorPage({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  return (
    <main className="flex min-h-screen flex-col items-center p-4">
      <div className="w-full max-w-2xl mt-16 mb-16">
        <div className="flex flex-col items-center text-center space-y-6">
          <h1 className="text-4xl font-bold font-aleo">Something went wrong</h1>
          
          <p className="text-sinc-700 text-lg max-w-md">
            We encountered an error while processing your request. This might be due to cached data or authentication issues.
          </p>

          <div className="flex flex-col md:flex-row gap-4 mt-4">
            <button
              onClick={reset}
              className="flex items-center justify-center gap-2 text-white bg-orange-500 hover:bg-orange-600 px-4 py-2 rounded-lg transition-colors"
            >
              Try again
            </button>
            
            <Link 
              href="/" 
              className="flex items-center justify-center gap-2 text-orange-500 hover:text-orange-600 transition-colors"
            >
              <ArrowLeft className="w-5 h-5" />
              <span>Back to home</span>
            </Link>
          </div>
        </div>

        <div className="mt-16 pt-16 border-t">
          <div className="flex items-center mb-8 gap-5">
            <Cookie className="h-6 w-6 flex-shrink-0" />
            <div>
              <h2 className="font-semibold mb-1 text-sm">Still having issues?</h2>
              <p className="text-sinc-700 text-sm">
                Try clearing your browser cookies and cache, then refresh the page. This often resolves authentication and data loading issues.
                <br /><br />
                If the problem persists, please contact us on{' '}
                <Link href="https://x.com/codestirring" className="text-orange-500 hover:underline">
                  Twitter
                </Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}