"use client";
import { Notes } from "~/components/notes";
import { Tasks } from "~/components/tasks";

import { Robot, Crown } from "@phosphor-icons/react/dist/ssr";
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Onboarding from "./onboarding";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { Rocket } from "lucide-react";
import Link from "next/link";
import { PremiumModal } from "./premiumModa";
import Landing from "./landing";
import AskCreate from "./askCreate";
import { CalendarSubscription } from "./CalendarSubscription";



export default function HomePage({ 
  initialNotes, 
  initialTasks, 
  initialTags,
  user, 
  signInUrl,
  userCount
}: { 
  initialNotes: NoteType[], 
  initialTasks: TaskType[], 
  initialTags: Tags[],
  user: any, 
  signInUrl: string,
  userCount: number
}) {

    const [notes, setNotes] = useState(initialNotes);
    const [tasks, setTasks] = useState(initialTasks);
    const [tags, setTags] = useState(initialTags);
    const [totalCounts, setTotalCounts] = useState({ notesCount: 0, tasksCount: 0 });

    const [showOnboarding, setShowOnboarding] = useState(
      !(initialNotes.length > 0 && initialTasks.length > 0 && initialTags.length > 0)
    );

    const showLimits = user && !user.premium;
    const FREE_LIMIT = 15; 
    const totalItems = totalCounts.notesCount + totalCounts.tasksCount;
    const isPastLimit = (count: number) => count >= FREE_LIMIT;

    useEffect(() => {
        const fetchTotalCounts = async () => {
          try {
            const response = await fetch('/api/check-limits');
            const counts = await response.json();
            setTotalCounts(counts);
          } catch (error) {
            console.error('Failed to fetch total counts:', error);
          }
        };
  
        if (showLimits) {
          fetchTotalCounts();
        }
      }, [showLimits]);

    useEffect(() => {
        const handleNewNote = (event: CustomEvent<NoteType>) => {
          const newNote = event.detail;
          if (newNote) {
            setNotes(prevNotes => [newNote, ...prevNotes]);
          }
        };
    
        const handleNewTasks = (event: CustomEvent<TaskType[]>) => {
          const newTasks = event.detail;
          if (newTasks && newTasks.length > 0) {
            setTasks(prevTasks => [...newTasks, ...prevTasks]);
          }
        };
    
        const handleNewTag = (event: CustomEvent<Tags>) => {
          const newTag = event.detail;
          if (newTag) {
            setTags(prevTags => [...prevTags, newTag]);
          }
        };
    
        window.addEventListener('newNote', handleNewNote as EventListener);
        window.addEventListener('newTasks', handleNewTasks as EventListener);
        window.addEventListener('tagCreated', handleNewTag as EventListener);
    
        return () => {
          window.removeEventListener('newNote', handleNewNote as EventListener);
          window.removeEventListener('newTasks', handleNewTasks as EventListener);
          window.removeEventListener('tagCreated', handleNewTag as EventListener);
        };
      }, []);

  if (notes.length === 0 && tasks.length === 0) {
    return <Landing userCount={userCount} />
  }

  return (
    <main className="flex flex-col items-center p-2 md:p-4">
      <div className="w-full max-w-5xl mt-4 mb-32 grid grid-cols-1 md:grid-cols-5 gap-6">
        <AnimatePresence>

        {showLimits && (
            <motion.div
            key="premium-alert"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="col-span-1 md:col-span-5 p-4"
          >
            <Alert className="border-0 bg-orange-100">
              <Crown className="h-4 w-4" />
              <AlertTitle>Free Plan Usage</AlertTitle>
              <AlertDescription className="text-sm flex flex-col md:flex-row justify-between items-center gap-8 pt-2">
                <div className="md:pr-24">
                  <PremiumModal userId={user.id} /> for unlimited items and AI generation.
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <div className="w-32 h-2 bg-gray-200 rounded-full overflow-hidden">
                      <div 
                        className={`h-full transition-all duration-300 ${isPastLimit(totalItems) ? 'bg-red-500' : 'bg-orange-500'}`}
                        style={{ width: `${Math.min((totalItems / FREE_LIMIT) * 100, 100)}%` }}
                      />
                    </div>
                    <span className="text-xs font-medium">
                      {totalItems}/{FREE_LIMIT} items
                      <span className="text-gray-500 ml-1">
                      ({totalCounts.notesCount} notes, {totalCounts.tasksCount} tasks)
                      </span>
                    </span>
                  </div>
                </div>
              </AlertDescription>
            </Alert>
          </motion.div>
          )}

          {!user && notes.length >= 3 && (
                    <motion.div
                        key="welcome"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="col-span-1 md:col-span-5 p-4"
                    >
                            <Alert>
                                <Rocket className="h-4 w-4" />
                                <AlertTitle>You've hit your limit without an account!</AlertTitle>
                                <AlertDescription className="text-sm pt-2">
                                    <Link href={signInUrl} className="underline">Create a free <strong>mmbl</strong> account</Link> to add custom tags that automatically get applied during note saving and add up to 100 tasks and notes!
                                    <br /><br />
                                    Pay a one-time fee of $39 for unlimited notes, searching and tagging.
                                </AlertDescription>
                            </Alert>
                    </motion.div>
                )}
        </AnimatePresence>

        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="col-span-1 md:col-span-2"
        >
          <Tasks initialTasks={tasks} user={user}/>
        </motion.div>

        <div className="col-span-1 md:col-span-3">      
          <AnimatePresence>
            {showOnboarding && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Onboarding 
                  initialNotes={notes} 
                  initialTasks={tasks} 
                  initialTags={tags}
                  onComplete={() => setShowOnboarding(false)}
                />
              </motion.div>
            )}
          </AnimatePresence>

          <AskCreate user={user} />
          
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.1 }}
            className="col-span-3"
          >
            <Notes initialNotes={notes} user={user} signInUrl={signInUrl} />
          </motion.div>
        </div>
      </div>
    </main>
  );
}