import { useState } from "react";
import { Calendar, CalendarCogIcon, CalendarRange, Copy, ExternalLink, Rss } from "lucide-react";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui/dialog";

import { Tooltip } from "./ui/tooltip";
import { TooltipContent, TooltipProvider, TooltipTrigger } from "@radix-ui/react-tooltip";

export function CalendarSubscription({ calendarToken }: { calendarToken: string }) {
  const calendarUrl = `${process.env.NEXT_PUBLIC_URL}/api/calendar/${calendarToken}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(calendarUrl);
    toast.success("Calendar URL copied to clipboard");
  };

  return (
    <Dialog>
            <DialogTrigger asChild>
        <div className="flex items-center gap-2 text-sm hover:text-orange-700 cursor-pointer">
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Rss className="w-4 h-4" />
                    </TooltipTrigger>
                    <TooltipContent className="bg-black text-white px-2 py-1 rounded-lg">
                        Subscribe to Calendar
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
      </DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <Calendar className="w-5 h-5 text-orange-500" />
            Calendar Subscription
          </DialogTitle>
          <DialogDescription>
            <div className="space-y-4 pt-4">
              <p className="text-sm text-gray-600">
                Subscribe to your tasks in your favorite calendar app to keep everything in sync.
              </p>

              <div className="flex items-center gap-2 bg-gray-50 p-2 rounded">
                <input
                  type="text"
                  value={calendarUrl}
                  readOnly
                  className="text-sm flex-1 bg-transparent"
                />
                <button onClick={handleCopy} className="p-1 hover:text-orange-600">
                  <Copy className="w-4 h-4" />
                </button>
              </div>

              <div className="space-y-2 text-sm text-gray-600">
                <h4 className="font-medium">How to subscribe:</h4>
                <ol className="list-decimal list-inside space-y-1">
                  <li>Copy the URL above</li>
                  <li>Open your calendar app</li>
                  <li>Add a new calendar subscription</li>
                  <li>Paste the URL when prompted</li>
                </ol>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}