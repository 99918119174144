import HeroVideoDialog from "./ui/hero-video-dialog";

export default function Video() {
  return (
    <HeroVideoDialog
    className="dark:hidden block"
    animationStyle="from-center"
    videoSrc="https://www.youtube.com/embed/oBJDKzPYzVk?si=b2NsXXy48KEPDL6x"
    thumbnailSrc="/mmbl_screenshot.png"
    thumbnailAlt="mmbl Hero Video"
  />
  )
}